
import {
  defineComponent,
  onMounted,
  computed,
  toRefs,
  watch,
} from 'vue';
import store from '@/plugins/vuex';

import useMulticontentPlayer from '@/components/multicontent/composables/useMulticontentPlayer';
import { useDi } from '@/plugins/di';
import { useI18n } from '@/plugins/vue-i18n';

import { Names } from '@/plugins/vue-router';
import { AtomType } from '@/domains/atom';

import MulticontentPlayerView from '@/views/App/Learning/Atoms/multicontent/MulticontentPlayerView.vue';
import TTBackButton from '@/components/ui/TTBackButton.vue';
import UpButton from '@/components/ui/UpButton.vue';
import AtomPlayerSidebar from '@/components/ui/AtomSidebar/AtomPlayerSidebar.vue';

// TODO: переименовать в AssignmentMulticontentPlayer и перенести в views/App
export default defineComponent({
  name: 'AssignmentMulticontentPlayer',
  components: {
    MulticontentPlayerView,
    TTBackButton,
    UpButton,
    AtomPlayerSidebar,
  },
  props: {
    playerSessionId: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const { playerSessionId } = toRefs(props);

    const {
      content,
      isCompleted,
      isUpToDate,
      isLoading,
      fetchSession,
      onCompleteHandler,
      onUpdateVideoProgressHandler,
      checkVersion,
    } = useMulticontentPlayer({ playerSessionId });

    const { tmt } = useDi();
    const { t } = useI18n();

    const breadcrumbs = computed(() => {
      return [
        {
          text: t('AssignmentMulticontentPlayer.breadcrumbs.myLearning'),
          to: {
            name: Names.R_APP_LEARNING_TRACKS,
          },
          'data-test-label': 'my-program-link',
        },
        {
          text: content.value?.title ?? t('AssignmentMulticontentPlayer.breadcrumbs.withoutName'),
          disabled: true,
          'data-test-label': 'material',
        },
      ];
    });

    const step = computed(() => ({
      atomType: AtomType.MULTICONTENT,
      name: content.value?.title,
      isCompleted: isCompleted.value,
      id: content.value?.id ?? '',
    }));

    const sendAnalytic = (eventValue: string, eventLabel: string) => {
      const data = {
        companyId: store.getters['company/companyId'],
        playerSessionId: props.playerSessionId,
      };

      tmt.sendEvent('send', 'click', eventValue, eventLabel, 'internalLink', data);
    };

    onMounted(() => {
      fetchSession();
      checkVersion();
    });

    watch(playerSessionId, () => {
      fetchSession();
      checkVersion();
    });

    return {
      content,
      isUpToDate,
      isCompleted,
      isLoading,
      breadcrumbs,
      step,
      sendAnalytic,
      onCompleteHandler,
      onUpdateVideoProgressHandler,
    };
  },
});
