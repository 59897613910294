
import {
  defineComponent, PropType, computed, ref,
} from 'vue';

import { useI18n } from '@/plugins/vue-i18n';

import LessonContent from '@/components/multicontent/LessonContent/LessonContent.vue';
import ProvidersButton from '@/components/ui/ProvidersButton';
import SeparateAtomNewVersionBanner from '@/components/ui/SeparateAtomNewVersionBanner';

import {
  LessonContentItem,
} from '@/domains/multicontent';
import { AtomType } from '@/domains/atom';

interface IAtomContent {
  title: string;
  description: string;
  body: LessonContentItem[];
  providers: [];
}

export default defineComponent({
  name: 'MulticontentPlayerView',
  components: {
    LessonContent,
    ProvidersButton,
    SeparateAtomNewVersionBanner,
  },
  props: {
    content: {
      type: Object as PropType<IAtomContent>,
      default: () => ({}),
    },
    isUpToDate: {
      type: Boolean,
      default: true,
    },
    playerSessionId: {
      type: Number,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();

    const contentBody = computed(() => props.content.body);
    const title = computed(() => props.content.title ?? t('Assignment.Course.defaultTrackName'));
    const description = computed(() => props.content.description ?? '');
    const providers = computed(() => props.content?.providers ?? []);

    const isOpenDescription = ref<Boolean>(false);

    const videoUpdate = (value: any) => {
      emit('update:video', value);
    };

    const onScrollBottom = (value: any) => {
      emit('scroll:bottom', value);
    };

    return {
      contentBody,
      title,
      providers,
      description,
      isOpenDescription,
      onScrollBottom,
      videoUpdate,
      AtomType,
    };
  },
});
