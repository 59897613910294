
import {
  computed, defineComponent, PropType, toRefs,
} from 'vue';

import { useRouter } from 'vue-router/composables';
import { useFeatureFlag } from '@/plugins/feature-flags';
import { useI18n } from '@/plugins/vue-i18n';

import { AtomType } from '@/domains/atom';
import { Names } from '@/plugins/vue-router';

import { useAssignment } from '@/domains/assignment/composables';

export default defineComponent({
  name: 'SeparateAtomNewVersionBanner',
  props: {
    atomType: {
      type: String as PropType<AtomType>,
      required: true,
    },
    playerSessionId: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const { atomType, playerSessionId } = toRefs(props);

    const { t } = useI18n();
    const router = useRouter();
    const { onAssignmentRunHandler } = useAssignment({ playerSessionId, atomType });

    const flags: Record<AtomType, boolean> = {
      [AtomType.MULTICONTENT]: useFeatureFlag('edit_publish_multi_content').value,
      [AtomType.MULTILEVELTEST]: useFeatureFlag('edit_publish_multilevel_test').value,
      [AtomType.QUIZ]: useFeatureFlag('edit_publish_quiz').value,
      [AtomType.SCORM]: useFeatureFlag('edit_publish_scorm').value,
      [AtomType.TRACK]: true,
      [AtomType.PROGRAM]: useFeatureFlag('new_atom_program').value,
      [AtomType.UNKNOWN]: false,
    };

    const isAvailable = computed<boolean>(() => flags[atomType.value] ?? false);
    const title = computed<string>(() => {
      const resolver: Record<AtomType, string> = {
        [AtomType.MULTICONTENT]: t('AtomHasNewVersionBanner.title'),
        [AtomType.SCORM]: t('AtomHasNewVersionBanner.title'),
        [AtomType.MULTILEVELTEST]: t('AtomHasNewVersionBanner.test.title'),
        [AtomType.QUIZ]: t('AtomHasNewVersionBanner.test.title'),
        [AtomType.TRACK]: t('AtomHasNewVersionBanner.track.title'),
        [AtomType.PROGRAM]: '',
        [AtomType.UNKNOWN]: '',
      };

      return resolver[atomType.value] ?? '';
    });
    const description = computed<string>(() => {
      const resolver: Record<AtomType, string> = {
        [AtomType.MULTICONTENT]: t('AtomHasNewVersionBanner.description'),
        [AtomType.SCORM]: t('AtomHasNewVersionBanner.description'),
        [AtomType.MULTILEVELTEST]: t('AtomHasNewVersionBanner.test.description'),
        [AtomType.QUIZ]: t('AtomHasNewVersionBanner.test.description'),
        [AtomType.TRACK]: t('AtomHasNewVersionBanner.track.description'),
        [AtomType.PROGRAM]: '',
        [AtomType.UNKNOWN]: '',
      };

      return resolver[atomType.value] ?? '';
    });

    const pageByType = (type: AtomType) => {
      const pages: Partial<Record<AtomType, Names>> = {
        [AtomType.QUIZ]: Names.R_APP_LEARNING_SESSION_PLAYER_QUIZ_INTRO,
        [AtomType.MULTICONTENT]: Names.R_APP_LEARNING_SESSION_PLAYER_MULTICONTENT_PLAYER,
        [AtomType.SCORM]: Names.R_APP_LEARNING_SESSION_PLAYER_SCORM_PLAYER,
        [AtomType.MULTILEVELTEST]: Names.R_APP_LEARNING_SESSION_PLAYER_MULTILEVELTEST_INTRO,
      };

      return pages[type] ?? '';
    };

    const goToNewVersion = async () => {
      const newPlayerSessionId = await onAssignmentRunHandler();

      await router.replace({
        name: pageByType(atomType.value),
        params: {
          playerSessionId: String(newPlayerSessionId),
        },
      });
    };

    return {
      isAvailable,
      title,
      description,
      goToNewVersion,
    };
  },
});
